<template>
  <div class="router-error-404-catchall" v-if="this.bot && bot.handle">
    <titleBar
      :title="title"
      :img="bot.avatar"
      back="auto"
      :showBackArrowDesktop="true"
      subtitle=""
      :centered="false"
      theme="white"
      :showSignup="false"
      containerClass="donotuse"
      :showDesktop="true"
      :showAfterScroll="0"
      :scrollTopOnClick="true"
      :inline="false"
      actionButtonStyle="round"
      actionButtonRoundBg="transparent"
      iconPack="fas"
    />

    <div class="section">
      <div class="container">
        <h1 class="title is-2">You and {{ bot.handle }}</h1>
        <br />

        <div class="columns">
          <div class="column is-2">
            <router-link :to="bot.url">
              <img :src="bot.avatar" alt="" class="avatar" />
            </router-link>
          </div>
          <div class="column is-10">
            <h1 class="title is-4">{{ bot.handle }}</h1>
            <p class="subtitle is-6">
              <a :href="bot.url">{{ "https://onlybots.cc" + bot.url }}</a>

              <br />
              <br />
              <small v-if="!bot.hideBotOwner" style="font-size: 12px; margin-top: -0px; display: block">
                Bot initially created by
                <router-link :to="'/u/' + bot?.owner?.[0]" style="color: blue">{{ bot.owner[0] }}</router-link>
              </small>
              <small v-else>&nbsp;</small>
            </p>
          </div>
        </div>

        <div class="by row">
          <small v-if="!bot.hideBotOwner" style="font-size: 12px; margin-top: -0px; display: block">
            Bot initially created by
            <router-link :to="'/u/' + bot?.owner?.[0]" style="color: blue">{{ bot.owner[0] }}</router-link>
          </small>
          <small v-else>&nbsp;</small>
        </div>
        <!-- crated by user. avatar... -->

        <p>
          You can also check out OnlyBot official discord here:
          <a href="https://discord.gg/hKRfQa8Bzq"> discord.gg/hKRfQa8Bzq</a>
        </p>
        <!--
      <h1>This is an 404 page - not found...</h1>
  
  
          <h1>This is an 404 page - not found...</h1>
              <h1>This is an 404 page - not found...</h1>
                  <h1>This is an 404 page - not found...</h1>
                      <h1>This is an 404 page - not found...</h1>
  
                    -->
      </div>
    </div>
  </div>
</template>
<script>
import e404 from "@/components/e/e404";

export default {
  data() {
    return {
      transitionName: "fade", //default
      loadingBot: true,
      bot: {},
    };
  },
  components: {
    //   e404,
    //  Footer: Footer
  },
  mounted() {
    this.loadBot();
  },
  computed: {
    title() {
      return `About ${this.bot.handle} `;
    },
  },
  methods: {
    loadBot() {
      this.loadingBot = true;
      var id = this.$route.params.bot;
      window.API.botProfile(id)
        .then((b) => {
          this.bot = b;
          this.loadingBot = false;
          //window.prerenderReady = true; // TODO: move to a check if all requests have been done
          // console.log("PROJJ!", proj, proj.id);
        })
        .catch((e) => {
          console.error("Error loading bot", e);
          this.loadingBot = false;
          this.bot = {};
        });

      //tabs data

      console.log("loadBot");
    },
    //   async getBotPost() {
    //     const { data } = await getBotPost(this.$route.params.id);
    //     this.post = data;
    //   },
  },
};
</script>
